<style>
  div {
    text-align: center;
  }
  a {
    text-decoration: none !important;
    border-bottom: none !important;
  }
  img {
    max-width: 220px;
    vertical-align: middle;
  }
</style>

<div class="c-content">
  <h3>Sponsors</h3>
  <a href="https://www.netlify.com/"><img src="/images/sponsors/Netlify_logo.svg" alt="Netlify logo"></a>
  <a href="https://www.mono.company/"><img src="/images/sponsors/mono-logo.png" alt="Netlify logo"></a>
</div>
