<style>
  div {
    text-align: center;
  }
</style>

<div class="c-content">
  <h3>Community</h3>
  <p>
    Want to help? Need help? Use Routify,
    <a href="https://github.com/roxiness/routify/issues">
      check issues on Github
    </a>
    , or talk to us on
    <a href="https://discord.gg/ntKJD5B">
      <img
        src="/images/discord.svg"
        width="24"
        alt=""
        style="position: relative; top: 0.5rem" />
      Discord
    </a>
    (this is the invite link).
  </p>
  <p>Let’s make the best router for Svelte 3!</p>
</div>
